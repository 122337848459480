@import './config'; 

.navbar{
    background-color: $background-colour;
    color: $primary-text-colour;
    border-bottom:1px solid #000;
}

.navbar .img{
    margin-left: 2rem;
}

.navbar-light .navbar-nav {
    margin-right: 2rem;
}

.navbar-light .navbar-nav .nav-link {
    color: $primary-text-colour;
    margin-right: 1rem;
    font-size: large;
    text-align: center ;
}