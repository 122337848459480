@import "./config";


#lessonsearch-img{
    height:100%;
    max-height:300px;
}

.searh {
    align-items: center;
}

.searchBox {
    max-width: 50%;
    text-align: center;
}

.searchBox .form-control {
    border-color: $secondary-text-colour;
    border-width: 1px;
    color: $secondary-text-colour;
}

.searchBox .form-control .placeholder {
    color: $secondary-text-colour;
}

.content {
    padding-bottom: 4rem;
}

#more-wrapper{
    display: flex;
    justify-content: center;
  }


#lessonsearch-col-text{
    padding-left: 1rem;
}

#lessonsearchrow{
    padding-top: 2rem;
}

#borderdiv{
    position: relative;
    left: 25%;
    padding-bottom: 2rem;
    border-bottom: 1px solid #000;
    width: 50%;
}



@media screen and (max-width: $small) {

    #lessonsearch-col-text{
        padding-left: 0rem;
    }

   .container .lessons{
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100vh ; 
        align-items: center;
        display: flex
    }
    .searchBox {
        max-width: 100%;
        text-align: center;
       
    }

    .bottom{
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100% ; 
        text-align: center;
        align-items: center;
        display: contents;
    }

    .bottom .row{
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100vh ; 
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .row{
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100% ; 
        text-align: center;
        align-items: center;
    }


}
