@import "./config";

.footer {
  background-color: $primary-colour;
  padding-top: 2rem;
  padding-bottom: 2rem;
  color: white;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.footer a {
  justify-content: center;
  align-items: center;
}
/* unvisited link */
.footer a:link {
  color: white;
}

/* visited link */
.footer a:visited {
  color: white;
}

/* mouse over link */
.footer a:hover {
  color: #59b08e;
}

/* selected link */
.footer a:active {
  color: #8b91c5;
}
