@import "./config";

.top{
    margin-top: 6rem;
}


@media screen and (max-width: $small) {

    .title {
        padding-top: 2rem;
        padding-bottom: 2rem;
        text-align: center;
        align-items: center;
    }

    .middle{
        margin-top: 0rem;
        padding-top: 6rem;
        padding-left: 1rem;
        padding-bottom: 1rem;
    }

    .bottom{
        margin-top: 0rem;
        padding-top: 6rem;
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100%;
    }

    .content{
        padding-top: 0rem;
        width: 100%;
    }

    .workon{
        padding-bottom: 2rem;
    }

   
}