@import "./config";

.top p {
    color: $secondary-text-colour;
}

.top {
    margin-top: 6rem;
    max-height: 50%;
}

#home-img {
    max-height: 100%;
    max-width: 100%;
}
.btn {
    background-color: $primary-colour;
    border: $primary-colour;
    text-align: center;
    margin-left: 1rem;
    margin-right: 1rem;
}

.middle {
    border-top: 1px solid #000;
    margin-top: 6rem;
    padding-top: 6rem;
}

.middle .title {
    padding-bottom: 2rem;
}

.content .first {
    padding-top: 2rem;
}

.content .middle {
    padding-top: 2rem;
}

.content .last {
    padding-top: 2rem;
}

.middle .img-thumbnail {
    border: 0;
}

#middleImg {
    max-width: 5rem;
    max-height: 100%;
    margin-bottom: 1rem;
}

.bottom {
    border-top: 1px solid #000;
    margin-top: 6rem;
    padding-top: 6rem;
    max-height: 100%;
    padding-bottom: 6rem;
    align-items: center;
}

.bottom .qb .btn {
    align-items: center;
    text-align: center;
}

#signup{
    background-color: #59B08E ;
    color: #F8F8FF;
}

@media screen and (max-width: $small) {


    .top {
        text-align: center;
        align-items: center;
        margin-top: 0rem;
        padding-top: 0rem;
        margin-bottom: 2rem;
    }
    #home-img {
        max-height: 100%;
        max-width: 100%;
        padding-left: 1rem;
    }

    .top .welcome{
        padding-top: 2rem;
        text-align: center;
        align-items: center;
    }

    .middle {
        border-top: 1px solid #000;
        margin-top: 1rem;
        padding-top: 2rem;
    }

    .middle .title {
        padding-bottom: 0rem;
    }
    .content .first {
        padding-top: 0rem;
        margin-left: 1rem;
    }

    .content .mid {
        padding-top: 2rem;
        margin-left: 1rem;
    }

    .content .last {
        padding-top: 2rem;
        margin-left: 1rem;
    }


    .btn {
        background-color: $primary-colour;
        border: $primary-colour;
        text-align: center;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}
