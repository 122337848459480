@import "./config";

.contact{
    height: 100vh;
}

.name{
    margin-top: 1rem;
    max-width: 80%;

}

.email{
    margin-top: 1rem;
    max-width: 80%;

}


.message{
    margin-top: 1rem;
    max-width: 80%;
    margin-bottom: 1rem;

}


.sendBtn{
    margin-top: 2rem;
    text-align: center;
    align-items: center;
    max-width: 100%;
}


@media screen and (max-width: $small) {
    .top {
        text-align: center;
        align-items: center;
        margin-top: 0rem;
        padding-top: 0rem;
        margin-bottom: 2rem;
    }
    .boxes{
        text-align: center;
        align-items: center;
        max-width: 100%;
    }

    .name{
        margin-top: 1rem;
        max-width: 80%;
        text-align: center;
        align-items: center;
        max-width: 100%;
    }
    
    .email{
        margin-top:1rem;
        max-width: 80%;
        text-align: center;
        align-items: center;
        max-width: 100%;
    }
    
    
    .message{
        margin-top: 1rem;
        margin-bottom: 1rem;
        max-width: 80%;
        text-align: center;
        align-items: center;
        max-width: 100%;
    }
    
    
    .sendBtn{
        margin-top: 2rem;
        text-align: center;
        align-items: center;
        max-width: 100%;
    }
}