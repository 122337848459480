@import "./config";



#learn-list{
    padding-bottom: 3rem;
}

#requirements-list{
    padding-bottom: 4rem;
}

#video-lessons{
    padding-bottom: 4rem;
}

.video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }
  
  .video-responsive iframe {
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
  
  .video {
    padding-bottom: 3rem;
  }

  
  
@media screen and (max-width: $small) {

    #lessonsearch-col-text{
        padding-left: 0rem;
    }

   .container .lessons{
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100vh ; 
        align-items: center;
        display: flex
    }
    .searchBox {
        max-width: 100%;
        text-align: center;
       
    }

    .bottom{
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100% ; 
        text-align: center;
        align-items: center;
        display: contents;
    }

    .bottom .row{
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100vh ; 
        text-align: center;
        align-items: center;
        display: flex;
        justify-content: center;
    }

    .row{
        padding-right: 0rem;
        margin-right: 0rem;
        width: 100% ; 
        text-align: center;
        align-items: center;
    }




}
