$background-colour: #FFFFFF;
$primary-colour: #598CAC; 
$primary-text-colour: black; 
$secondary-text-colour: #00334E;

$small: 400px;
$medium: 900px;

h1{
    font-weight: 500;
}

h2{
    font-weight: 350;
}


.top{
    margin-top: 6rem;
    max-height: 50%;
}



amplify-s3-image {
    --height: 100%;
    --width: 100%;
  }
